import { useAppSelector } from "store/hooks";
import { generalLoaderSelector } from "store/selectors";
import "./style.scss";

const AppLoader: React.FC = () => {
	const loaderSelector = useAppSelector(generalLoaderSelector);

	if (!loaderSelector) return null;

	return (
		<div className="app-loader">
			<span className="loading loading-spinner loading-lg" />
		</div>
	);
};
export default AppLoader;
