import { useEffect, useState } from "react";
import ColorSelect from "shared/components/color-select";
import AppLoader from "shared/design-system/components/app-loader";
import { Color } from "shared/models/config.model";
import { AnalyticsService } from "shared/services/analytics.service";
import { generalGetConfig } from "store/actions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { generalConfigSelector } from "store/selectors";
import "./style.scss";

const HomePage: React.FC = () => {
	const dispatch = useAppDispatch();

	const config = useAppSelector(generalConfigSelector);

	const [step, setStep] = useState<number>(1);

	const [magliaColor, setMagliaColor] = useState<Color>();
	const [logoColor, setLogoColor] = useState<Color>();
	const [logoText, setLogoText] = useState<boolean>(false);

	const handleMagliaColorChange = (color: Color) => {
		setMagliaColor(color);
		AnalyticsService.addEvent(
			"tshirt_color_choose_" + color.label.toUpperCase()
		);
	};

	const handleLogoColorChange = (color: Color) => {
		setLogoColor(color);
		AnalyticsService.addEvent(
			"tshirt_logo_color_choose_" + color.label.toUpperCase()
		);
	};

	const handleLogoTextChange = (res: boolean) => {
		setLogoText(res);
		AnalyticsService.addEvent("tshirt_logo_text_choose_" + (res ? "SI" : "NO"));
	};

	const handlePrev = () => {
		if (step == 1) setStep(3);
		else setStep(step - 1);
	};

	const handleNext = () => {
		if (step == 3) setStep(1);
		else setStep(step + 1);
	};

	useEffect(() => {
		dispatch(generalGetConfig())
			.unwrap()
			.then((res) => {
				if (res) {
					console.log("res", res);
					setMagliaColor(res.tshits.variants[0]);
					setLogoColor(res.tshits.logo[0]);
				}
			});
	}, []);

	if (!config) return null;

	return (
		<>
			<div className="h-full max-h-full flex flex-col justify-center app-home-page">
				<div className="h-full flex-1 m-5 image-render-container">
					<img
						src={
							process.env.PUBLIC_URL +
							"/assets/imgs/t-shirt/" +
							magliaColor?.imgName
						}
						className="image-combine"
					/>
					<img
						src={process.env.PUBLIC_URL + "/assets/imgs/" + logoColor?.imgName}
						className="image-combine"
					/>
					{logoText && (
						<img
							src={
								process.env.PUBLIC_URL +
								"/assets/imgs/" +
								logoColor?.textLogoName
							}
							className="image-combine"
						/>
					)}
				</div>

				<div className="flex-0 h-52 w-full bg-base-100 rounded-t-3xl shadow p-4">
					{step == 1 && (
						<div>
							<div className="grid grid-cols-6 text-center items-center w-full">
								<div onClick={handlePrev} role="button">
									❮
								</div>

								<h6 className="col-span-4 mx-8 font-semibold text-center">
									Colore Maglia
									<span className="ms-2 text-gray-500 font-normal">1 / 3</span>
								</h6>

								<div onClick={handleNext} role="button">
									❯
								</div>
							</div>

							<div className="h-28 w-full">
								<ColorSelect
									colors={config?.tshits?.variants}
									onChange={handleMagliaColorChange}
									selectedColor={magliaColor!}
									className="mt-10"
								/>
							</div>
						</div>
					)}

					{step == 2 && (
						<div>
							<div className="grid grid-cols-6 text-center items-center w-full">
								<div onClick={handlePrev} role="button">
									❮
								</div>

								<h6 className="col-span-4 mx-8 font-semibold text-center">
									Colore Logo
									<span className="ms-2 text-gray-500 font-normal">2 / 3</span>
								</h6>

								<div onClick={handleNext} role="button">
									❯
								</div>
							</div>

							<div className="h-28 w-full">
								<ColorSelect
									colors={config?.tshits?.logo}
									onChange={handleLogoColorChange}
									selectedColor={logoColor!}
									className="mt-10"
								/>
							</div>
						</div>
					)}

					{step == 3 && (
						<div>
							<div className="grid grid-cols-6 text-center items-center w-full">
								<div onClick={handlePrev} role="button">
									❮
								</div>

								<h6 className="col-span-4 mx-8 font-semibold text-center">
									Testo Logo
									<span className="ms-2 text-gray-500 font-normal">3 / 3</span>
								</h6>

								<div onClick={handleNext} role="button">
									❯
								</div>
							</div>

							<div className="h-28 w-full text-center">
								<div className="mt-10">
									<div
										className={
											"badge py-4 w-24 me-5 " +
											(logoText ? "badge-neutral" : "badge-outline")
										}
										role="button"
										onClick={() => handleLogoTextChange(true)}
									>
										SI
									</div>
									<div
										className={
											"badge py-4 w-24 " +
											(!logoText ? "badge-neutral" : "badge-outline")
										}
										role="button"
										onClick={() => handleLogoTextChange(false)}
									>
										NO
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>

			<AppLoader />
		</>
	);
};
export default HomePage;
