// import { ErrorResponseDto } from "@api/client";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Config } from "shared/models/config.model";

const enum GeneralReducer {
	setGeneralError = "[GENERAL] Set general error",
	resetGeneralError = "[GENERAL] Reset general error",
	getConfig = "[GENERAL] Get Config",
}

export const setGeneralError = createAction<any>(
	GeneralReducer.setGeneralError
);

export const resetGeneralError = createAction<void>(
	GeneralReducer.resetGeneralError
);

export const generalGetConfig = createAsyncThunk<Config | undefined, void>(
	GeneralReducer.getConfig,
	async () => {
		try {
			return await fetch(process.env.PUBLIC_URL + "/config.json").then((res) =>
				res?.json()
			);
		} catch (e) {
			alert("errore");
		}
	}
);
