// Import the functions you need from the SDKs you need
import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";

export namespace AnalyticsService {
	// TODO: Add SDKs for Firebase products that you want to use
	// https://firebase.google.com/docs/web/setup#available-libraries

	// Your web app's Firebase configuration
	// For Firebase JS SDK v7.20.0 and later, measurementId is optional
	const firebaseConfig = {
		apiKey: "AIzaSyCH8J2OMUeWEeUNz4OU6dzQXUA70BqXOMo",
		authDomain: "athletix-clothes.firebaseapp.com",
		projectId: "athletix-clothes",
		storageBucket: "athletix-clothes.appspot.com",
		messagingSenderId: "1085354831094",
		appId: "1:1085354831094:web:9b2c19811c71ffc43b50d7",
		measurementId: "G-L51WBXY56N",
	};

	const app = initializeApp(firebaseConfig);

	export const addEvent = (eventName: string) => {
		logEvent(getAnalytics(app), eventName);
	};
}
