import HomePage from "pages/home";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const AppRouter: React.FC = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="" element={<HomePage />} />
			</Routes>
		</BrowserRouter>
	);
};
export default AppRouter;
