import { useEffect, useRef, useState } from "react";
import { Color } from "shared/models/config.model";
import ColorOption from "./color-option";

interface Props {
	colors: Color[];
	selectedColor: Color;
	onChange: (color: Color) => void;

	className?: string;
}

const ColorSelect: React.FC<Props> = (props) => {
	const { colors, onChange, className, selectedColor } = props;

	const containerRef = useRef<HTMLDivElement>(null);

	const [elementCentered, setElementCentered] = useState<boolean>(false);

	const handleClick = (color: Color) => {
		onChange(color);
	};

	useEffect(() => {
		document.getElementById(`color_${selectedColor?.color}`)?.scrollIntoView({
			behavior: "auto",
			block: "center",
			inline: "center",
		});
	}, []);

	useEffect(() => {
		setElementCentered(
			(containerRef.current?.clientWidth || 0) -
				(containerRef.current?.scrollWidth || 0) >=
				0
		);
	}, [containerRef]);

	return (
		<div
			ref={containerRef}
			className={`
				w-full flex items-center ${
					elementCentered ? "justify-center" : ""
				}  overflow-x-auto hide-scrollbar ${className}
			`}
		>
			{colors?.map((color, i) => (
				<ColorOption
					id={`color_${color.color}`}
					key={`color_${i}`}
					color={color}
					isSelected={color.color == selectedColor?.color}
					onClick={handleClick}
				/>
			))}
		</div>
	);
};
export default ColorSelect;
