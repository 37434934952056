import { Color } from "shared/models/config.model";
import "./style.scss";

interface Props {
	color: Color;
	onClick: (color: Color) => void;

	id?: string;
	isSelected?: boolean;
	className?: string;
}

const ColorOption: React.FC<Props> = (props) => {
	const { id, color, isSelected, onClick, className } = props;

	const handleClick = () => {
		onClick(color);
	};

	return (
		<div className={"color-option " + className} id={id}>
			<button
				className={`color-option-btn ${isSelected ? "isSelected" : ""}`}
				onClick={() => handleClick()}
			>
				<div
					className="color-option-btn-bg"
					style={{ backgroundColor: color.color }}
				/>
			</button>

			<div className="color-option-label">{isSelected && color.label}</div>
		</div>
	);
};
export default ColorOption;
