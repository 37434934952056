import {
	Action,
	configureStore,
	getDefaultMiddleware,
	ThunkAction,
} from "@reduxjs/toolkit";
import { generalReducer } from "./reducer";

export const store = configureStore({
	reducer: {
		general: generalReducer,
	},
	middleware: getDefaultMiddleware({
		serializableCheck: false,
	}),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;

export interface ThunkApiConfig {
	state: RootState;
}
